<template>
  <van-list v-model="loading" :finished="finished" :finished-text="finishedText" @load="onLoad">
    <div class="articles">
      <div class="item" v-for="(item, index) in list" :key="index" @click="toDetail(item)">
        <van-image fit="cover" :src="item.cover" />
        <div class="right">
          <div class="title van-ellipsis">{{ item.title }}</div>
          <div class="desc">
            <span>{{ item.createTime }}</span>
          </div>
        </div>
      </div>
    </div>
  </van-list>
</template>

<script>
import { getNoticeList } from '@/api/eventApi'

export default {
  name: 'EmploymentInformation',
  components: {},
  data() {
    return {
      list: [],
      current: 1,
      currentList: -1,
      loading: false,
      finished: false,
      finishedText: '',
    }
  },
  // 计算属性
  computed: {},

  // watch
  watch: {},

  // 创建完成（可以访问当前this实例）
  async created() {},
  mounted() {},
  // 方法集合
  methods: {
    // 跳转到详情页
    toDetail(item) {
      this.$router.push({ path: '/employmentInformationDetail', query: { id: item.id } })
    },
    async onLoad() {
      if (this.currentList !== 0) {
        const res = await getNoticeList({
          current: this.current,
          interfaceType: 'employment',
          size: 10,
          // tenantId: this.$store.getters.userInfo.tenantId,
          category: 10,
          // createDept: this.$store.getters.userInfo.deptId
        })
        this.currentList = res.data.records ? res.data.records.length : 0
        this.list = this.list.concat(res.data.records)
        this.current++
        this.loading = false
      } else {
        this.finished = true
        this.finishedText = '没有更多了'
      }
    },
  },
}
</script>

<style lang="scss"></style>
<style lang="scss" scoped>
// @import url(s); 引入公共css类
.articles {
  width: 100%;
  margin-top: 12px;
  padding: 0 20px;
  .item {
    width: 100%;
    display: flex;
    align-items: center;
    background-color: #fff;

    margin-bottom: 12px;
    box-sizing: border-box;
    padding: 14px 12px;
    border-radius: 8px;
  }
  .van-image {
    width: 100px;
    height: 60px;
    border-radius: 4px;
    overflow: hidden;
    margin-right: 10px;
    flex-shrink: 0;
  }
  .right {
    flex: 1;
    height: 60px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .title {
      width: 200px;
      font-size: 12px;
      font-weight: 700;
    }
    .desc {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        font-size: 12px;
        color: #999999;
      }
    }
  }
}
</style>
